<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card header="Dati Identificativi" header-tag="header">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-input
                        vid="code"
                        :name="beForm[rep].code.label"
                        :label="beForm[rep].code.label"
                        v-model="form[rep].code"
                        placeholder="Inserisci un codice causale"
                        :rules="getRules('code')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        vid="caption"
                        :name="beForm[rep].caption.label"
                        :label="beForm[rep].caption.label"
                        v-model="form[rep].caption"
                        placeholder="Inserisci una descrizione"
                        :rules="getRules('caption')"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>

              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button @click="resetForm()" variant="lisaweb" size="sm"
                  >Reset</b-button
                >
                <b-button
                  @click="$router.back()"
                  variant="lisaweb"
                  size="sm"
                  class="float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>

      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import FormMixin from "@/mixins/FormMixin";

export default {
  mixins: [FormMixin, ShortcutMixin],
  data() {
    return {
      repository: "payment_reference",
      id: this.$route.params.id,
      section_opt: [],
      form: {
        payment_reference: {
          code: null,
          caption: null,
          title: null,
        },
      },
    };
  },
  components: {
    BaseIcon,
    BaseInput,
  },
  methods: {
    onSubmit() {
      this.isLoading = true;
      this.form[this.rep].title =
        this.form[this.rep].code + " - " + this.form[this.rep].caption;
      this.store(this.repository)
        .then(() => {
          this.$store.dispatch("auth/payment_references");
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Causale Creata`,
          });
          this.shortcut(
            "module.CONT.CONA",
            null,
            "#PaymentReferences",
            "filterPaymentReference"
          );
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  created() {
    this.isLoading = true;
    this.fetchCreateForm(this.repository).then(() => {
      this.isLoading = false;
    });
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
};
</script>
